import React, { useState } from 'react';
import './orderContainer.css';

function OrderContainer(props){

    const [openOrder, setOpenOrder] = useState(false);

    if(openOrder === false){
        return <button className="orderConfirmation" onClick={() => {setOpenOrder(true)}}>{props.name}</button>
    }else{
        return(
            <div>
                <button className="orderConfirmation" onClick={() => {setOpenOrder(false)}}>{props.name}</button>
                <div>{props.name}</div>
            </div>
        )
    }
}

export default OrderContainer;