import React, { useState } from 'react';

import './uploadBreakdown.css';

function UploadBreakdown(props) {
    const [showResults, setShowResults] = useState(false);

    if(!showResults){
        return ( 
            <div>
                <button type="button" onClick={() => {setShowResults(true)}} >See Breakdown</button>
            </div>  
        )
    }else{
        return (
            <div>
                <button className="returnButton" type="button" onClick={() => {setShowResults(false)}} >Close</button>
                {console.log(props.order)}
                <table className="orderTotals">
                    <tbody>
                        <tr>
                            <th>Name:</th><th>Order Total</th><th>Product Price</th><th>Ship Price</th>
                        </tr>
                {
                    
                    props.orders.map((order) => {
                        
                        return(
                            <tr key={order.id}>
                                <td>{order.shipping_name}</td><td><strong>£{order.order_cost.toFixed(2)}</strong></td><td>£{order.products_cost.toFixed(2)}</td><td>£{order.calculated_shipping_cost.toFixed(2)}</td>
                            </tr>
                        )
                    })
                }
                </tbody>
                </table>
                {/* <pre>{JSON.stringify(props.order, null, 2)}</pre> */}
            </div>
        )
    }
}


export default UploadBreakdown;