import React from "react";

import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button'

import './historyBase.css';

import { gql, useQuery } from '@apollo/client';

const useStyles = makeStyles({
    table: {
      minWidth: 650,
    },
  });

function HistoryBase(props) {

    const GET_ORDERS = gql`
        query Batches($ccp_id: ID!){
            batches(ccp_id: $ccp_id){
                id
                createdAt
                total_cost
                paid
                processed
                orders{
                    id
                    ccp_order_id
                    orderproducts{
                        sku
                        qty
                    }
                    shipping_name
                    shipping_address1
                    shipping_address2
                    shipping_city
                    shipping_county
                    shipping_country
                    shipping_postcode
                    shipping_type
                    shipping_customer_notes
                    original_shipping_cost
                    calculated_shipping_cost
                    products_cost
                    order_cost
                    taxed_shipping
                }
            }
        }
    `;


    const { loading, error, data } = useQuery(GET_ORDERS, {
        variables: { ccp_id: props.ccp_id },
    });

    if(loading) return "Retrieving Account Details...";
    if(error) return `There's a small problem: ${error.message}`
    return (
        <div>


            <TableContainer component={Paper}>
                <Table className="orderTable" size="small" aria-label="A Dense Table">
                    <TableHead>
                        <TableRow>
                            <TableCell><strong>Date and Time</strong></TableCell>
                            <TableCell><strong>Orders</strong></TableCell>
                            <TableCell><strong>Total</strong></TableCell>
                            <TableCell><strong></strong></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.batches.map((batch) => {
                        const batchDate = new Date(batch.createdAt);
                            return(
                                <TableRow key={batch.id}>
                                    <TableCell>{batchDate.toDateString()} - {batchDate.getHours()}:{batchDate.getMinutes()}</TableCell>
                                    <TableCell>{batch.orders.length}</TableCell>
                                    <TableCell><strong>&pound;{batch.total_cost.toFixed(2)}</strong></TableCell>
                                    <TableCell>
                                    {
                                        batch.paid ? <Button variant="outlined" color="primary" type="button" disabled>Paid</Button> : <Button  variant="outlined" color="primary" type="button">Pay</Button>
                                    }
                                    </TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>



            {/* <table>
                <tbody>
                {data.batches.map((batch) => {
                    const batchDate = new Date(batch.createdAt);
                    return(
                        <tr key={batch.id}>
                            <td>{batchDate.toDateString()} - {batchDate.getHours()}:{batchDate.getMinutes()}</td>
                            <td>Orders: {batch.orders.length}</td>
                            <td><strong>&pound;{batch.total_cost}</strong></td>
                            <td>
                            {
                                batch.paid ? <button type="button" disabled>Paid</button> : <button type="button">Pay</button>
                            }
                            </td>
                        </tr>
                    )
                })}
                </tbody>
            </table> */}

        </div>
    );
}

export default HistoryBase;
