import React, { useState } from 'react';

import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import WarningIcon from '@material-ui/icons/Warning';
import './uploadFinish.css';

import { gql, useMutation } from '@apollo/client';

import Button from '@material-ui/core/Button'

function UploadFinish(props) {
    if(props.payment === true){
        return(<div><CheckCircleOutlineIcon style={{ color: "green", fontSize: 100 }}/><h3>Order Confirmed</h3><p>Thank you very much. Your order will be processed and dispatched as soon as we can.</p></div>)
    }else if(props.payment === false){
        return(<div><WarningIcon style={{ color: "grey", fontSize: 100 }}/><h3>Order Confirmed</h3><p>Your order has been uploaded, however <strong>it will not be dealt with or dispatched until payment is given. Stock will be held for these, but only for 48 hours. After which our system will reject your orders.</strong></p></div>)
    }else{
        return (<div>Not entirely sure how you got here. Contact Support.</div>)
    }
}



export default UploadFinish;