import React, { useState } from 'react';
import { Route, withRouter, useHistory, Link} from 'react-router-dom'

import './App.css';

import { gql, useQuery } from '@apollo/client';

import Grid from '@material-ui/core/Grid'
import FormGroup from '@material-ui/core/FormGroup';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Input from '@material-ui/core/Input';
import Button from '@material-ui/core/Button';
import HistoryIcon from '@material-ui/icons/History';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import BuildIcon from "@material-ui/icons/Build";
import HelpIcon from '@material-ui/icons/Help';

import UploadBase from "./components/upload/uploadBase"
import HistoryBase from "./components/history/historyBase"
import ToolsBase from "./components/tools/toolsBase"

function App(props) {

  let search = props.location.search;
  let params = new URLSearchParams(search);

  let [dsCustomer, setDsCustomer] = useState(params.get('dsgql_id'));
  let [ccpCustomer, setCcpCustomer] =  useState(params.get('ccp_id'));

  const SEARCH_QUERY = gql`
  query FindCustomer($dsgql_id: ID!){
    customer(id: $dsgql_id){
      id
      ccp_id
      admin
    }
  }`;

  const {loading, error, data } = useQuery(SEARCH_QUERY , {variables: {dsgql_id: dsCustomer}});
  
  if(loading) return <p>Checking...</p>;
  if(error) return <h1>Unauthorized</h1>;

  if(data.customer.ccp_id !== ccpCustomer){
    console.log(ccpCustomer);
    console.log(data.customer.ccp_id);
    return <h1>Unauthorized</h1>
  }

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justify="center"
      style={{ minHeight: '100vh' }}
    >

      <Grid item xs={5}>
        <div className="App">
          <Link to="/"><h1>Dropshipping Spreadsheet Upload</h1></Link>
          <ButtonGroup color="primary" variant="text" aria-label="outlined primary button group">
            <Link to="/upload">
              <Button>
                <ArrowUpwardIcon/> Upload
              </Button>
            </Link>
            <Link to="/history">
              <Button>
                <HistoryIcon/> History
              </Button>
            </Link>
            <Link to="/tools">
              <Button>
                <BuildIcon/> Tools
              </Button> 
            </Link>            
            <Link to="/help">
              <Button>
                <HelpIcon/> Help
              </Button> 
            </Link>
          </ButtonGroup>
          <hr/>
          <Route path="/upload/" component={() => <UploadBase client={props.client} dsgqlid={dsCustomer} admin={data.customer.admin} ccp_id={data.customer.ccp_id} history={props.history}/>} />
          <Route path="/history/" component={() => <HistoryBase client={props.client} admin={data.customer.admin} ccp_id={data.customer.ccp_id} history={props.history}/>} />
          <Route path="/tools/" component={() => <ToolsBase admin={data.customer.admin} />} />
          <p><small>This program will only work with .csv (Text Delimited Spreadsheets) and .xlsx (Excel) files. </small></p>
        </div>
      </Grid>
    </Grid>
  );
}

export default withRouter(App);
