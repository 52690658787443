import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter, Route, Switch } from 'react-router-dom'
import { ApolloProvider, createHttpLink, InMemoryCache, ApolloClient } from '@apollo/client';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { setContext } from "@apollo/link-context"

import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_PUB);

let search = window.location.hash;
let cutme = search.substring(3, search.length);

console.log(`params: ${cutme}`);
let params = new URLSearchParams(search);
console.log(params.get("dsgql_id"));

const token = params.get("gwkey") + ":" + params.get("gwpass");

// const httpLink = createHttpLink({
//   uri: 'https://api.ekwholesale.co.uk/dropship/',
// });

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_DS_URL,
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  console.log(`Token: ${token}`);
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: `apiKey ${token}`,
    }
  }
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
  fetchOptions: {
    mode: 'no-cors',
  },
});

ReactDOM.render(
  <HashRouter basename="/">
    <React.StrictMode>

        <ApolloProvider client={client}>
        <Elements stripe={stripePromise}>
          <App client={client} />
          </Elements>
        </ApolloProvider>

    </React.StrictMode>
  </HashRouter>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
