import React, { useState } from 'react';

import { Link } from 'react-router-dom';

import './uploadButton.css';

import { gql, useMutation } from '@apollo/client';

import Button from '@material-ui/core/Button'

import Loading from '../../images/loading.gif'

function UploadButton(props) {

    const PUSH_SKIP_MUTATION = gql`
    mutation makeBatch($ccp_id: ID!, $orders: [OrderInput!]!, $skip_errors: Boolean!){
        makeBatch(
            ccp_id: $ccp_id,
            orders: $orders,
            skip_errors: $skip_errors
        ),{
            customer {
                ccp_id
                shopify_id
                shipping_variance
            }
            orders{
                orderproducts{
                    original_price
                    calculated_price
                }
                shipping_name
                shipping_address1
                shipping_address2
                shipping_city
                shipping_county
                shipping_country
                shipping_customer_notes
                shipping_type
                original_shipping_cost
                calculated_shipping_cost
                products_cost
                order_cost
                taxed_shipping
            }
            createdAt
            id
            total_cost
        }
    }
`;    



    const PUSH_MUTATION = gql`
    mutation makeBatch($ccp_id: ID!, $orders: [OrderInput!]!){
        makeBatch(
            ccp_id: $ccp_id,
            orders: $orders
        ),{
            customer {
                ccp_id
                shopify_id
                shipping_variance
            }
            orders{
                orderproducts{
                    original_price
                    calculated_price
                }
                shipping_name
                shipping_address1
                shipping_address2
                shipping_city
                shipping_county
                shipping_country
                shipping_customer_notes
                shipping_type
                original_shipping_cost
                calculated_shipping_cost
                products_cost
                order_cost
                taxed_shipping
            }
            createdAt
            id
            total_cost
        }
    }
`;    
    const [pushMutation, { loading: mutationLoading, error: mutationError, data: mutationData }] = useMutation(PUSH_MUTATION, { errorPolicy: 'all', onCompleted(data){ props.setProcessedOrder(data.makeBatch) }});
    const [pushSkipMutation, { loading: mutationSkipLoading, error: mutationSkipError, data: mutationSkipData }] = useMutation(PUSH_SKIP_MUTATION, { errorPolicy: 'all', onCompleted(data){ props.setProcessedOrder(data.makeBatch) }});

    if(mutationLoading || mutationSkipLoading) return <img src={Loading} alt="loading"/>;
    if(mutationError || mutationSkipError){
    
        const errorsArray = mutationError.graphQLErrors[0].message.split("<br/>");
        // console.log(errorsArray);
    
        // take errors
    
        // split errors
    
        // for each error, new line.

    
        return (
            <div>
                {
                    errorsArray.map((gqlError, index) => {
                        return(
                            <p className="error" key={index}>{gqlError}</p>
                        )
                    })
                }
                <br/>
                <br/>
                {/* <p className="error"><small>{mutationError.graphQLErrors[0].message}</small></p> */}
                <Link to="/upload">
                    <Button
                        variant="outlined"
                        color="secondary">
                        Retry Upload
                    </Button>
                </Link>
                {
                    props.admin
                    ?
                        <Button
                            onClick={e => {
                                    e.preventDefault();
                                    pushSkipMutation({ variables: { "ccp_id": props.ccpid, "orders": props.orders, skip_errors: true } } );
                                }
                            }
                            variant="outlined"
                            color="secondary">
                            Ignore Errors
                        </Button>
                    :
                    <span></span>
                }
            </div>
        )
    }

    return (
        <div>
        {
            mutationData && <p>{mutationData.makeBatch.id}</p>
        }   
            <form
                onSubmit={e => {
                    e.preventDefault();
                    pushMutation({ variables: { "ccp_id": props.ccpid, "orders": props.orders } } );
                }}
            >
             <Button
                type="submit"
                variant="contained"
                color="primary"> 
                Send All Orders
            </Button>
             <br/>
             <br/>
            <Link to="/upload">
                <Button
                    variant="outlined"
                    color="secondary">
                    Go Back
                </Button>
                <br/>
            </Link>        
            </form>
        </div>
    )
}



export default UploadButton;