import React, { useState, useEffect } from "react";

import UploadBreakdown from "./uploadBreakdown";

import './uploadPayments.css';

import { gql } from '@apollo/client'

import {
  CardElement,
  useStripe,
  useElements
} from "@stripe/react-stripe-js";

export default function UploadPayments(props) {
  const [succeeded, setSucceeded] = useState(false);
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState('');
  const [disabled, setDisabled] = useState(true);
  const [clientSecret, setClientSecret] = useState('');
  const stripe = useStripe();
  const elements = useElements();

  const confirmPayment = function(id){
    // const clientForHeaders = useApolloClient();
    const CONFIRM_ME = gql`
      mutation PaidForBatch(
          $id: ID!
        ) {
        paidForBatch(
          id: $id
        ) {
          id
          paid
        }
      }
    `;
  
    return props.client.mutate({
      mutation: CONFIRM_ME,
      variables: {
        "id": id
      },     
      fetchPolicy: "no-cache"   // select appropriate fetchPolicy
   }).then((data) => {
      console.log(data.data.paid);
      props.setPayment(true)
   }).catch((err) => {
     console.log(err) 
     return false;
   })
  
  }  

  useEffect(() => {
    // Create PaymentIntent as soon as the page loads
    window
      .fetch("http://localhost:8449/payments/makePayment", {
        method: "POST",
        headers: {
          "Authorization": "apiKey 2rEvInVDO3tWC9DcJtQARg:2XWOhcoS6Fwd1Zdm2vAFpy",
          "Content-Type": "application/json"
        },
        body: JSON.stringify({items: [{ id: "xl-tshirt" }]})
      })
      .then(res => {
        return res.json();
      })
      .then(data => {
        setClientSecret(data.clientSecret);
      });
  }, []);

  const cardStyle = {
    style: {
      base: {
        color: "#32325d",
        fontFamily: 'Arial, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#32325d"
        }
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a"
      }
    }
  };

  const handleChange = async (event) => {
    // Listen for changes in the CardElement
    // and display any errors as the customer types their card details
    setDisabled(event.empty);
    setError(event.error ? event.error.message : "");
  };

  const handleSubmit = async ev => {
    ev.preventDefault();
    setProcessing(true);

    const payload = await stripe.confirmCardPayment(clientSecret, {
      id: "cus_HpiEoKUhwj39Ej",
      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: {
          name: ev.target.name.value
        }
      }
    });

    if (payload.error) {
      setError(`Payment failed ${payload.error.message}`);
      setProcessing(false);
    } else {
      setError(null);
      setProcessing(false);
      setSucceeded(true);
    }
  };

  return (
    <div>
      <div id="finalPriceContainer">
        <span>Total Cost: </span>
        <p>&pound;{props.processedOrder.total_cost.toFixed(2)}</p>
        <div>
          <UploadBreakdown orders={props.processedOrder.orders}/>
          <textfield value={props.processedOrder}></textfield>
        </div>
      </div>
    <form id="payment-form" onSubmit={handleSubmit}>
      <CardElement id="card-element" options={cardStyle} onChange={handleChange} />
      <button
        disabled={processing || disabled || succeeded}
        id="submit"
      >
        <span id="button-text">
          {processing ? (
            <div className="spinner" id="spinner"></div>
          ) : (
            "Pay"
          )}
        </span>
      </button>
      {/* Show any error that happens when processing the payment */}
      {error && (
        <div className="card-error" role="alert">
          {error}
        </div>
      )}
      {/* Show a success message upon completion */}
      <p className={succeeded ? "result-message" : "result-message hidden"}>
        Payment succeeded, see the result in your
        <a
          href={`https://dashboard.stripe.com/test/payments`}
        >
          {" "}
          Stripe dashboard.
        </a> Refresh the page to pay again.
      </p>
    </form>
    {props.admin
          ? <div><button type="button" onClick={() => {confirmPayment(props.processedOrder.id)}} className="skipButton">Skip and Confirm</button><button type="button" onClick={() => {props.setPayment(false)}} className="skipButton">Pay Later</button></div>
          : <span></span>
    }    
    </div>
  );
  // skip and confirm will allow the react app to note that payment is true, thus taking you to the payment okay screen with a big tick.
  // pay later will give you an okay, but remind you that you must make a payment for your order before it will be processed by us. Say cutoff times etc.
}