//take file
//parse file to json
//show headers if headers don't match whats required so they can be mapped.
//display json data.

import React, { useState } from 'react';

import { Link } from 'react-router-dom';

import './uploadHeaders.css';

import Button from '@material-ui/core/Button'

function UploadHeaders(props) {

    const [mapCount, setMapCount] = useState(0);
    const [confirmationDisabled, setConfirmationDisabled] = useState(true);
    const [mapChoice, setMapChoice] = useState(
        [
            {
                id: 0,
                name: "Product Sku",
                disabled: false,
                chosen: null
            },
            {
                id: 1,
                name: "Quantity",
                disabled: false,
                chosen: null
            },
            {
                id: 2,
                name: "Customer Name",
                disabled: false,
                chosen: null
            },
            {
                id: 3,
                name: "Address Line 1",
                disabled: false,
                chosen: null
            },
            {
                id: 4,
                name: "Address Line 2",
                disabled: false,
                chosen: null
            },
            {
                id: 5,
                name: "City",
                disabled: false,
                chosen: null
            },
            {
                id: 6,
                name: "County",
                disabled: false,
                chosen: null
            },
            {
                id: 7,
                name: "Postcode",
                disabled: false,
                chosen: null
            },
            {
                id: 8,
                name: "Shipping Type",
                disabled: false,
                chosen: null
            },                                                                                                                 
        ]
    )

    function submitMapping(e){

        e.preventDefault();

        let newBatchArray = [];
        let newOrderArray = [];

        let orderMap = props.orders;
        // console.log(props.hasHeaders);
        if(props.hasHeaders === true){
            orderMap.shift();
        }
        // console.log("ORDERMAP");
        // console.log(orderMap);
        orderMap.map((order) => {
            newOrderArray = [];
            //console.log(`order: ${order}`);
            mapChoice.map((headerChoice) => {
                newOrderArray.push(order[headerChoice.chosen])
            })
            newBatchArray.push(newOrderArray)
        })

        

        let sortArray = newBatchArray.sort((a, b) => parseFloat(a[7]) - parseFloat(b[7]));

        

        let orderObject = [];
        let orderproducts = [];
        let countryChoice;
        let customerOrder;
        let address2;
        let sameOrder = false;
        let productLine;
        let nextOrderIndex;


        for(let x = 0; x < sortArray.length; x++){
            nextOrderIndex = x + 1;
            
            if(sameOrder === false){
                orderproducts = [];
            }

            // ammend to mutation requirements

            productLine = {
                sku: sortArray[x][0],
                qty: sortArray[x][1],
            }

            orderproducts.push(productLine)

            if(nextOrderIndex in sortArray){
                if(sortArray[x][2] === sortArray[nextOrderIndex][2] && sortArray[x][7] === sortArray[nextOrderIndex][7]){
                    sameOrder = true;
                    continue;
                }
            }

            customerOrder = [];

            countryChoice = "United Kingdom";
            address2="";
            if(sortArray[x][4]){
                address2 = sortArray[x][4].toString()
            }

            if(!Number.isInteger(sortArray[x][8])){
                countryChoice = sortArray[x][8];
            }
            //for each order picked up
            customerOrder = {
                orderproducts: orderproducts,
                shipping_name: sortArray[x][2],
                shipping_address1: sortArray[x][3].toString(),
                shipping_address2: address2,
                shipping_city: sortArray[x][5],
                shipping_county: sortArray[x][6],
                shipping_country: countryChoice,
                shipping_postcode: sortArray[x][7].toString(),
                shipping_type: sortArray[x][8].toString(),
                shipping_customer_notes: "Test Notes"
            }
            orderObject.push(customerOrder);
            sameOrder = false;
        }

        // console.log("Sent Out: ");
        // console.log(orderObject)

        props.setFile(orderObject);
        props.setCorrectHeadings(true);
    }

    function countMapping(){
        let count = 0;
        for(let x = 0; x < mapChoice.length; x++){
            if(mapChoice[x]["disabled"] === true){
                count ++;
            }
        }
        if(count === 9){
            setConfirmationDisabled(false);
        }
        
        setMapCount(count);
    }

    function disableOnSelect(e){
        const id = e.target.getAttribute("id");
        document.getElementById(id).classList.add("disabled");
        document.getElementById(id).disabled = true;
    }

    function cancelSelection(e){
        const id = e.target.getAttribute("id");
        document.getElementById(id).disabled = false;
    }

    function selectMapping(e){
        let choice = e.target.value.split(",");
        let currentChoiceArray = mapChoice;
        if(mapChoice[choice[0]]["disabled"] === false){
            currentChoiceArray[choice[0]]["disabled"] = true;
            currentChoiceArray[choice[0]]["chosen"] = choice[1];
            setMapChoice(currentChoiceArray);
        }
        disableOnSelect(e);
        countMapping()
    }

    //console.log(props.orders);
    let countOriginalLines = -1;
    let asciiEq = 64;
    // console.log(props.orders)

    return (
        <div>
            <h2>Map your Columns {mapCount}/9</h2>
            <table>
                <tbody>
            {/* {console.log("test")} */}
            {/* {console.log(props.orders)} */}
            {
            props.orders[0].map((orderLine, index) => {
                countOriginalLines++;
                // console.log(orderLine)
                asciiEq++;
                return (
                    <tr key={index}>
                        <td>
                            <span className="columnHeader">Column {String.fromCharCode(asciiEq)}</span><br/><span className="example">{orderLine}</span>
                        </td>
                        <td>
                            <select id={countOriginalLines} onChange={selectMapping}>
                                <option value="">Select Mapping Option</option>
                                {
                                    mapChoice.map((mapping, index) => {
                                        let value = mapping["id"]+","+countOriginalLines;
                                        return(
                                            <option key={`text-${index}`} value={value} disabled={mapping["disabled"]}>{mapping["name"]}</option>
                                        )
                                    })
                                }

                            </select>
                        </td>
                    </tr>
                )
            })
            }
                </tbody>
            </table>
            <div className="notes">
                <p><strong>All mapping options must be selected on at least one column.</strong></p>
            </div>
         <form>
             <Button
                type="button"
                variant="contained"
                color="primary"
                disabled={confirmationDisabled}
                onClick={(e) => {submitMapping(e)}}>
                All Good
            </Button>
            <Link to="/upload">
                <Button
                    variant="outlined"
                    color="secondary">
                    Go Back
                </Button>
                <br/>
            </Link>        
            </form>
        </div>
    )
}

export default UploadHeaders;