//take file
//parse file to json
//show headers if headers don't match whats required so they can be mapped.
//display json data.

import React, { useState } from 'react';

import { Link } from 'react-router-dom';

import Button from '@material-ui/core/Button'

import './uploadResults.css';

import OrderContainer from "./order/orderContainer"
import UploadButton from "./uploadButton"

import { makeStyles } from '@material-ui/core/styles';

function UploadResults(props) {
        if(props.orders.length > 0){
        return(    
        <div>
            <div className="orderCount">
                {console.log("Data on Results: ")}
                {console.log(props.orders)}
                <h2><span>{props.orders.length}</span> Orders Found</h2>
                <hr/>
            <p>Double check your orders before hitting confirm. If there are any issues, our system will notify you and <strong>the whole order sheet will be stopped from being processed.</strong></p>
            <p>All confirmed orders <strong>must</strong> be paid in order to be sent out. Any deffered payments will result in no orders being sent out for your customers until paid. </p>
            <hr/>                
            </div>

            <h3>Is everything good to go?</h3>
            <UploadButton admin={props.admin}ccpid={props.ccpid} orders={props.orders} setProcessedOrder={props.setProcessedOrder} />
        </div>
        )
        }else{
            return(
                <div>
                    <div className="orderCount">
                    <p><strong>We couldn&apos;t find any orders.</strong> The most common cause is not starting with a clean sheet or template. If you are still struggling to get this to work right, <a href="#"><strong>please download and fill in an example order sheet</strong></a></p>
                    <Link to="/upload">
                        <Button
                            variant="outlined"
                            color="secondary">
                            Go Back
                        </Button>
                        <br/>
                    </Link>
                    </div>
                </div>
            )
        }
}

export default UploadResults;