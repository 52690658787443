import React, { useState } from 'react';

import './uploadBase.css';

import UploadForm from "./uploadForm"
import UploadHeaders from "./uploadHeaders"
import UploadResults from "./uploadResults"
import UploadPayments from "./uploadPayments"
import UploadFinish from "./uploadFinish"

import he from "he";

import { gql } from '@apollo/client'

import * as XLSX from 'xlsx';

// const gbPrices = require("../shippingTypes/gb.json");
const euPrices = require("../../shipping/eu.json");

const UploadBase = (props) => {

    const [file, setFile] = useState(null);
    const [ccp, setCcp] = useState(props.ccp_id);
    const [tickBoxHeaders, setTickBoxHeaders] = useState(true);
    const [checkHeadersOkay, setCheckHeadersOkay] = useState(true);
    const [correctHeadings, setCorrectHeadings] = useState(false);
    const [processedOrder, setProcessedOrder] = useState(null);
    const [processedPayment, setProcessedPayment] = useState(null);

    const checkHeaders = async function(heads){
      // const clientForHeaders = useApolloClient();
      const CHECK_HEADERS = gql`
        query Headers(
            $header_sku: String!
            $header_qty: String!
            $header_name: String!
            $header_address1: String!
            $header_address2: String!
            $header_city: String!
            $header_county: String!
            $header_postcode: String!
            $header_type: String!
            $header_id: String!
          ) {
          headers(
            header_sku: $header_sku,
            header_qty: $header_qty,
            header_name: $header_name,
            header_address1: $header_address1,
            header_address2: $header_address2,
            header_city: $header_city,
            header_county: $header_county,
            header_postcode: $header_postcode,
            header_type: $header_type,
            header_id: $header_id
          ) {
            result
          }
        }
      `;

      return props.client.query({
        query: CHECK_HEADERS,
        variables: {
          "header_sku": heads[0],
          "header_qty": heads[1],
          "header_name": heads[2],
          "header_address1": heads[3],
          "header_address2": heads[4],
          "header_city": heads[5],
          "header_county": heads[6],
          "header_postcode": heads[7],
          "header_type": heads[8],
          "header_id": heads[9],
        },     
        fetchPolicy: "cache-first"   // select appropriate fetchPolicy
     }).then((data) => {
       
        if(data.data.headers.result === true){
          return true;
        }else{
          return false;
        }
     }).catch((err) => {
       console.log(err) 
       return false;
     })

    }

    function handleEmptyRows(j, headers) {

        let filtered = [];
        let x = 0;
    
        if (headers === true) {
          x = 1
        }
    
        for (x; x < j.length; x++) {
          if (j[x].length == 10) {
            filtered.push(j[x])
          }
        }
    
        return filtered;
        
    }

    function compare(a, b) {
      // Use toUpperCase() to ignore character casing
      const postcodeA = a[7].toString().toUpperCase().trim();
      const postcodeB = b[7].toString().toUpperCase().trim();
    
      let comparison = 0;
      if (postcodeA > postcodeB) {
        comparison = 1;
      } else if (postcodeA < postcodeB) {
        comparison = -1;
      }
      return comparison;
    }    

    async function handleUploadChange(e, headers) {

        e.preventDefault();
    
        var files = e.target.files, f = files[0];
        var reader = new FileReader();
        // console.log("Initial input for handleUploadChange");
        if(!headers){
            reader.onload = function (e) {
                var data = e.target.result;
                let readedData = XLSX.read(data, { type: 'binary' });
                const wsname = readedData.SheetNames[0];
                const ws = readedData.Sheets[wsname];

                // /* Convert array to json*/
                const dataParse = XLSX.utils.sheet_to_json(ws, { header: 1, raw: true, defval: " ", blankrows: false });
                let ammendForEmptyRows = handleEmptyRows(dataParse, headers);
                // console.log("From Base: ");
                // console.log(dataParse);
                // console.log(ammendForEmptyRows)
                setFile(ammendForEmptyRows); 
            }
            reader.readAsBinaryString(f)
            return;           
        }

        let orderObject = [];
        
        reader.onload = async function (e) {
            var data = e.target.result;
            let readedData = XLSX.read(data, { type: 'binary' });
            const wsname = readedData.SheetNames[0];
            const ws = readedData.Sheets[wsname];

            // /* Convert array to json*/
            const dataParse = XLSX.utils.sheet_to_json(ws, { header: 1 ,defval: "", blankrows: false});
            // console.log(dataParse);

            // await checkHeaders(dataParse[0]);

            let headCheck = await checkHeaders(dataParse[0]);

            console.log("Check Headers:");
            console.log(headCheck);

            if(headCheck !== true){
              await setFile(dataParse);                
              await setCorrectHeadings(false);
              await setCheckHeadersOkay(false);
              return;
            }
            
            let ammendForEmptyRows = handleEmptyRows(dataParse, headers);

            //sort 

            // let sortArray = ammendForEmptyRows.sort((a, b) => parseFloat(b[7]) - parseFloat(a[7]));
            let sortArray = ammendForEmptyRows.sort(compare);
            // console.log("AAAAA")
            // console.log(sortArray);

            let orderproducts = [];
            let countryChoice;
            let customerOrder;
            let address2;
            let sameOrder = false;
            let productLine;
            let nextOrderIndex;


            for(let x = 0; x < sortArray.length; x++){
                nextOrderIndex = x + 1;
                
                if(sameOrder === false){
                    orderproducts = [];
                }
                
                // ammend to mutation requirements
                
                productLine = {
                    sku: sortArray[x][0],
                    qty: sortArray[x][1],
                }

                orderproducts.push(productLine)

                if(nextOrderIndex in sortArray){
                    if(sortArray[x][2] === sortArray[nextOrderIndex][2] && sortArray[x][7] === sortArray[nextOrderIndex][7]){
                        sameOrder = true;
                        continue;
                    }
                }

                customerOrder = [];
                
                address2="";

                if(sortArray[x][4]){
                    address2 = sortArray[x][4].toString()
                }

                countryChoice = "United Kingdom";

                if(!Number.isInteger(sortArray[x][8])){
                  // if in eu
                  const euCheck = Object.keys(euPrices).find(key => euPrices[key] == euPrices[sortArray[x][8]]);
                  //console.log(euCheck);
                  //console.log(euPrices[sortArray[x][8]].name);

                  if(euPrices[euCheck] !== undefined && euPrices[sortArray[x][8]].name){
                    countryChoice = euPrices[euCheck].name;
                  }else if(sortArray[x][8].toLowerCase() == "usa"){
                    countryChoice = "United States"
                  }else if(sortArray[x][8].toLowerCase() == "ca"){
                    countryChoice = "Canada"
                  }else if(sortArray[x][8].toLowerCase() == "aus"){
                    countryChoice = "Australia"
                  }else{ 
                    //proper error response will go here
                    countryChoice = "Error with sheet shipping"
                  }

                }
                //for each order picked up
                customerOrder = {
                    orderproducts: orderproducts,
                    shipping_name: he.encode(sortArray[x][2]),
                    shipping_address1: he.encode(sortArray[x][3].toString()),
                    shipping_address2: he.encode(address2),
                    shipping_city: he.encode(sortArray[x][5]),
                    shipping_county: he.encode(sortArray[x][6]),
                    shipping_country: countryChoice.toString(),
                    shipping_postcode: sortArray[x][7].toString(),
                    shipping_type: sortArray[x][8].toString(),
                    shipping_customer_notes: "Test Notes"
                }
                orderObject.push(customerOrder);
                sameOrder = false;
            }

            // console.log("The Sorted Array: ");
            // console.log(sortArray);
            // console.log("The ammended object: ");
            // console.log(orderObject)

            setCheckHeadersOkay(true);
            setFile(orderObject);   

            if(orderObject.length === 0){
              return;
            }


            if(props.admin){
              setCcp(sortArray[0][9])
            }
                    
        };
        
        await reader.readAsBinaryString(f)
        
      }

      if(processedPayment !== null){
        return(
          <div>
            {props.admin
              ? <div className="message">ADMIN ACCOUNT</div>
              : <div></div>
            }
            <div id="finishedContainer"><UploadFinish payment={processedPayment} /></div> 
          </div>
        )
      }

      if(!file && !processedOrder){
        // Show Base upload form
        return(
          <div>
            {props.admin
              ? <div className="message">ADMIN ACCOUNT</div>
              : <div></div>
            }
            <div id="uploadContainer"><UploadForm headers={tickBoxHeaders} setHeaders={setTickBoxHeaders} onChangeFile={handleUploadChange} /></div> 

          </div>
        )

      }else if(!processedOrder && !correctHeadings && !checkHeadersOkay){
        // Show Header Mapping if required
        return(
          <div>
            {props.admin
              ? <div className="message">ADMIN ACCOUNT</div>
              : <div></div>
            }
           <div id="headerContainer"><UploadHeaders ccpid={ccp} orders={file} hasHeaders={tickBoxHeaders} setCorrectHeadings={setCheckHeadersOkay} setFile={setFile}/></div>
          </div>
        )

      }else if(!processedOrder){
        // Show all processed orders
        return(
          <div>
            {props.admin
              ? <div className="message">ADMIN ACCOUNT</div>
              : <div></div>
            }
            <div id="checkContainer"><UploadResults admin={props.admin} ccpid={ccp} orders={file} setProcessedOrder={setProcessedOrder}/></div>
          </div>
        )

      } else {
        // Take payment
        return(
          <div>
            {props.admin
              ? <div className="message">ADMIN ACCOUNT</div>
              : <div></div>
            }          
            <div id="paymentContainer"><UploadPayments admin={props.admin} client={props.client} processedOrder={processedOrder} setPayment={setProcessedPayment}/></div>
          </div>
        )

      }
}

export default UploadBase;