//take file
//parse file to json
//show headers if headers don't match whats required so they can be mapped.
//display json data.

import React, { useState } from 'react';

import './toolsBase.css';

function ToolsBase() {

    return (
        <div>Possible Tools: Rough Listing Price Calculator</div>
    )
}

export default ToolsBase;