//take json data as prop when headers don't match
//show required headers and a dropdown to what header is which
//show example of file over 3/4 lines.

import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Input from '@material-ui/core/Input';
import Button from '@material-ui/core/Button';

function UploadForm(props) {


    const handleChange = (event) => {
        props.setHeaders(event.target.checked);
      };
    
      // const [open, setOpen] = useState(0);
    
    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Input
                    color="primary"
                    name="uploadbutton"
                    placeholder="Upload your file here"
                    required={true}
                    key="main"
                    type="file"
                    onChange={(e) => props.onChangeFile(e, props.headers)}
                />
            </Grid>
            <Grid item xs={12}>
                <strong><small>Settings</small></strong>
            </Grid>
            {/* 
            <Input
                color="primary"
                name="uploadbutton"
                placeholder="Upload your file here"
                required={true}
                type="file"
                onChange={(e) => handleUploadChange(e, headers)}
            />                 */}
            <Grid item xs={12}>
                <FormControlLabel
                    control={<Checkbox checked={props.headers} onChange={handleChange} name="checkedA" />}
                    label="The file uses headers"
                />
            </Grid>
            {/* <Grid item xs={12}>
                <Link to="/upload/submit">
                    <Button
                        variant="contained"
                        color="primary"
                    >Upload Sheet</Button>
                </Link>
            </Grid> */}
        </Grid>

      )
}

export default UploadForm;